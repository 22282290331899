// i18next-extract-mark-ns-start insights-blogs

import { graphql } from 'gatsby';
import React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const BlogsPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data,
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO title={t('seo_title')} description={t('seo_description')} />

			<Banner
				title={t('title')}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<ResourceListing resources={data.resources} />
		</Page>
	);
};

export default BlogsPage;
export const pageQuery = graphql`
	query ($language: String!) {		
		locales: allLocale(
			filter: { ns: { in: ["insights-blogs", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		resources: allMarkdownRemark(
			filter: { 
				fileAbsolutePath: { regex: "/news/blogs/" } 
				fields: { language: { eq: $language } }
			}
			sort: { fields: frontmatter___date, order: DESC }
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}
		banner: file(relativePath: { eq: "content/banner-insights.jpg" }) {
			...imageBreaker
		}
	}
`;

